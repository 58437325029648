import useSWR from 'swr';
import { userCache } from '@mentimeter/user';
import { MentiError } from '@mentimeter/errors/sentry';
import {
  getGetByWorkspaceRequest,
  getGetByWorkspaceResponse,
  type GetGetByWorkspaceResponse,
} from '@core-api/workspace-request-upgrade-messages/workspaces/{workspace_id}/request-upgrade-messages/get-by-workspace';
const WORKSPACE_REQUEST_UPGRADE_MESSAGE_SWR_CACHE_KEY =
  'workspace-request-upgrade-message';

export const useWorkspaceRequestUpgradeMessage = (
  workspaceId: number | undefined,
) => {
  const { data } = useSWR<GetGetByWorkspaceResponse>(
    workspaceId ? WORKSPACE_REQUEST_UPGRADE_MESSAGE_SWR_CACHE_KEY : null,
    async () => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();
      const response = await fetch(
        getGetByWorkspaceRequest({
          region,
          userAuth: sessionToken,
          workspaceId: workspaceId!,
        }),
      );

      if (!response.ok) {
        throw new MentiError(
          'Error fetching workspace request upgrade message',
          {
            feature: 'workspace-request-upgrade-message',
          },
        );
      }

      return getGetByWorkspaceResponse(response);
    },
  );

  return { message: data?.message };
};

import type { ReactNode } from 'react';
import { Box } from '@mentimeter/ragnar-ui';

export const Right = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      flexDirection="row"
      justifyContent="flex-end"
      alignItems="center"
      py="space2"
    >
      {children}
    </Box>
  );
};

import * as React from 'react';
import { Trigger } from '@radix-ui/react-dropdown-menu';
import type { DropdownMenuTriggerProps } from '@radix-ui/react-dropdown-menu';
import { cn } from '@mentimeter/ragnar-tailwind-config';

export const DropdownMenuTrigger = ({
  className,
  ...rest
}: DropdownMenuTriggerProps) => (
  <Trigger asChild {...rest} className={cn(className)} />
);

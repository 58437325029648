// This file is automatically generated. DO NOT EDIT.

import { type SeriesCollaborator } from '../../../../types/response/SeriesCollaborator';
import { fromSnakeKeys } from '@api/internal';

export interface GetCollaboratorsResponse {
  data: Array<SeriesCollaborator>;
}

export async function getCollaboratorsResponse(
  response: Response,
): Promise<GetCollaboratorsResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetCollaboratorsResponse;
}

import React from 'react';

function lazyWithCache<T>({
  cacheKey,
  importFn,
  cache,
}: {
  cacheKey: T;
  importFn: () => Promise<React.ComponentType>;
  cache: Map<T, React.ComponentType>;
}) {
  const cachedContent = cache.get(cacheKey);

  if (cachedContent) {
    return cachedContent;
  }

  return React.lazy(async () => {
    try {
      const dynamicContent = await importFn();

      cache.set(cacheKey, dynamicContent);
      return { default: dynamicContent };
    } catch (error) {
      throw new Error(
        `Question modules: Failed to load  dynamically for: '${cacheKey}'. Error message: ${error}`,
      );
    }
  });
}

export function createLazyWithCache<T>() {
  const cache = new Map<T, React.ComponentType>();

  return ({
    cacheKey,
    importFn,
  }: {
    cacheKey: T;
    importFn: () => Promise<React.ComponentType>;
  }) => lazyWithCache({ cacheKey, importFn, cache });
}

import React, { forwardRef } from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box, type BoxT } from '../Box';

export interface ButtonGroupProps extends BoxT {
  showSeparators?: boolean;
  children: React.ReactNode;
}

export const ButtonGroup = forwardRef(
  (
    {
      showSeparators = false,
      children,
      extend,
      className,
      ...boxProps
    }: ButtonGroupProps,
    forwardedRef,
  ) => {
    const defaultClasses = [
      '[&>button:hover]:z-[1]',
      '[&>button:focus-visible]:z-[2]',
      '[&>button:not(:first-of-type)]:ml-[-2px]', // check this value
      '[&>button:not(:first-of-type)]:rounded-l-none',
      '[&>button:not(:last-of-type)]:rounded-r-none',
    ];

    const separatorClasses = [
      '[&>button:enabled+button:enabled]:before:content-zwsp',
      '[&>button:enabled+button:enabled]:before:absolute',
      '[&>button:enabled+button:enabled]:before:top-[4px]',
      '[&>button:enabled+button:enabled]:before:bottom-[4px]',
      '[&>button:enabled+button:enabled]:before:left-[-1px]',
      '[&>button:enabled+button:enabled]:before:w-[1px]',
      '[&>button:enabled+button:enabled]:before:z-[2]',
      '[&>button:enabled+button:enabled]:before:bg-inherit',
      '[&>button:enabled+button:enabled]:before:invert',
      '[&>button:enabled+button:enabled]:before:grayscale',
      '[&>button:enabled+button:enabled]:before:contrast-[10]',
      '[&>button:enabled+button:enabled]:before:opacity-[0.4]',
    ];

    return (
      <Box
        role="group"
        ref={forwardedRef}
        className={clsx(
          ['flex-row', 'items-stretch'],
          defaultClasses,
          showSeparators && separatorClasses,
          className,
        )}
        {...boxProps}
      >
        {children}
      </Box>
    );
  },
);

// This file is automatically generated. DO NOT EDIT.

import { getCoreURL } from '@api/internal';

export interface GetCollaboratorsRequest {
  region: 'eu' | 'us';
  userAuth: string;
  /** The series id */
  seriesId: string;
}

export function getCollaboratorsRequest(
  requestParams: GetCollaboratorsRequest,
): Request {
  const coreURL = getCoreURL(requestParams.region);
  const url = `${coreURL}/collaboration/series/${requestParams.seriesId}/collaborators`;
  return new Request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${requestParams.userAuth}`,
    },
  });
}

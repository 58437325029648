import {
  Box,
  Tooltip,
  IconButton,
  type IconButtonProps,
} from '@mentimeter/ragnar-ui';
import { ArrowLeftIcon, MentimeterSymbol } from '@mentimeter/ragnar-visuals';
import { HeaderTitle } from './HeaderTitle';
import { HeaderStatus } from './HeaderStatus';
import { HeaderSurveyBadge } from './HeaderSurveyBadge';

interface HeaderBackProps {
  backLinkHref: string;
  tooltipText: string;
  onBackLinkClick: () => void;
  children: React.ReactNode;
  size?: IconButtonProps['size'];
  showLogo?: boolean;
}

export const HeaderBack = ({
  backLinkHref,
  tooltipText,
  onBackLinkClick,
  children,
  size = 'compact',
  showLogo = false,
}: HeaderBackProps) => {
  return (
    <>
      <IconButton
        id="back-to-dashboard"
        variant="secondary"
        aria-label={tooltipText}
        href={backLinkHref}
        onClick={onBackLinkClick}
        size={size}
        borderRadius="full"
      >
        {showLogo ? <MentimeterSymbol /> : <ArrowLeftIcon color="inherit" />}
      </IconButton>
      <Tooltip
        referenceId="back-to-dashboard"
        description={tooltipText}
        placement="bottom"
      />
      <Box
        flex="1 1 auto"
        alignItems="center"
        height="100%"
        flexDirection="row"
      >
        {children}
      </Box>
    </>
  );
};

type HeaderTitleProps = React.ComponentProps<typeof HeaderTitle>;

HeaderBack.Title = (props: HeaderTitleProps) => <HeaderTitle {...props} />;

type HeaderStatusProps = React.ComponentProps<typeof HeaderStatus>;

HeaderBack.Status = (props: HeaderStatusProps) => <HeaderStatus {...props} />;

type HeaderBadgeProps = React.ComponentProps<typeof HeaderSurveyBadge>;

HeaderBack.Badge = (props: HeaderBadgeProps) => (
  <HeaderSurveyBadge {...props} />
);

import {
  TrackingContext,
  type ModuleId,
  type QuestionSubType,
} from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';
import type { SlideCutstomizationActions } from './types';

const trackChangedSlideCustomization = ({
  customizationType,
  action,
  value,
  slideType,
}: {
  customizationType: 'Basic' | 'Advanced';
  action: SlideCutstomizationActions;
  value?: string | undefined;
  slideType: ModuleId | undefined;
}) => {
  trackUser({
    event: 'Changed slide customization',
    properties: {
      context: TrackingContext.Editview,
      placement: 'Edit',
      'customization type': customizationType,
      action,
      value,
      'slide type': slideType,
    },
  });
};

export const trackSwitchedVisualization = (
  value: QuestionSubType,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Switched visualization',
    value: value || undefined,
    slideType,
  });
};

export const trackAddedOption = (slideType: ModuleId | undefined) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Added option',
    slideType,
  });
};

export const trackRemovedOption = (slideType: ModuleId | undefined) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Removed option',
    slideType,
  });
};

export const trackModifiedOptionText = (slideType: ModuleId | undefined) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Modified option text',
    slideType,
  });
};

export const trackAddedImageOnOption = (slideType: ModuleId | undefined) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Added image on option',
    slideType,
  });
};

export const trackModifiedNumberOfResponses = (
  value: number,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified number of responses',
    value: value === Number.POSITIVE_INFINITY ? 'Unlimited' : value.toString(),
    slideType,
  });
};

export const trackAddImageClicked = (slideType: ModuleId | undefined) => {
  trackUser({
    event: 'Slide add image clicked',
    properties: {
      context: TrackingContext.Editview,
      placement: 'Edit',
      'slide type': slideType || 'Unknown',
    },
  });
};

export const trackUpdatedSegmentation = (slideType: ModuleId | undefined) => {
  trackUser({
    event: 'Updated segmentation',
    properties: {
      context: TrackingContext.Editview,
      'question type': slideType,
    },
    options: {
      set_last_used: true,
    },
  });
};

export const trackSwitchedSegmentation = (slideType: ModuleId | undefined) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Switched segmentation',
    slideType,
  });
};

export const trackOpenedSegmentation = (slideType: ModuleId | undefined) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Opened segmentation',
    slideType,
  });
};

export const trackToggledVoteOnResponses = (
  value: boolean,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled vote on responses',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackSwitchedQuestionsCanBeAsked = (
  value: string,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Switched questions can be asked',
    value,
    slideType,
  });
};

export const trackToggledQuestionsVisibleEveryone = (
  value: boolean,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Toggled questions visible to everyone',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackToggledModeration = (value: boolean, slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Toggled Moderation',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackModifiedVotesPerPerson = (
  value: number,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified votes per person',
    value: value.toString(),
    slideType,
  });
};

export const trackToggledVotesOnDifferentResponses = (
  value: boolean,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled votes on different responses',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackToggledStatementAverage = (
  value: boolean,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled statement average',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackToggledCorrectArea = (
  value: boolean,
  slideType: ModuleId | undefined,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled correct area',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackToggledStatementsCanBeSkipped = (
  value: boolean,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled statements can be skipped',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackModifiedAxisLabel = (slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified axis label',
    slideType,
  });
};

export const trackModifiedAxisValue = (slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified axis value',
    slideType,
  });
};

export const trackModifiedMidValueSetting = (slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified mid value setting',
    slideType,
  });
};

export const trackChoseGridBackground = () => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Chose grid background',
    slideType: 'rating',
  });
};

export const trackAddedCustomGrid = () => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Added custom grid',
    slideType: 'rating',
  });
};

export const trackModifiedAxisLimits = () => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified axis limits value',
    slideType: 'rating',
  });
};
export const trackToggledChooseCorrectAnswer = (
  value: boolean,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled choose correct answer',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackToggledShowResponsesAsPercentage = (
  value: boolean | undefined,
  slideType: ModuleId | undefined,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled show responses as percentage',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackModifiedAxisText = (axis: string) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified axis text',
    value: axis,
    slideType: 'rating',
  });
};

export const trackModifiedCustomAxisLabel = () => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified custom axis label',
    slideType: 'rating',
  });
};

export const trackModifiedCorrectAnswer = (slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Modified correct answer',
    slideType,
  });
};

// Guess the number
export const trackModifiedAnswersBetween = (slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Modified answers between',
    slideType,
  });
};

// Guess the number
export const trackModifiedScaleIncrement = (slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Modified scale increment',
    slideType,
  });
};

// Guess the number
export const trackToggledErrorMargin = (
  value: boolean,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled error margin',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

// Guess the number
export const trackModifiedErrorMarginValue = (slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified error margin value',
    slideType,
  });
};

export const trackModifiedOptionType = (slideType: ModuleId | undefined) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Modified option type',
    slideType,
  });
};

export const trackModifiedOptionRequired = (
  slideType: ModuleId | undefined,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Basic',
    action: 'Modified option required',
    slideType,
  });
};

export const trackToggledQuizScoring = (enabled: boolean) => {
  trackUser({
    event: 'Toggled quiz scoring',
    properties: { enabled },
  });
};

export const trackModifiedSecondsToAnswer = (slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Modified seconds to answer',
    slideType,
  });
};

export const trackToggledPointsForFastAnswer = (
  value: boolean,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled points for fast answer',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackToggledLeaderboard = (
  value: boolean,
  slideType: ModuleId,
) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled leaderboard',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackToggledQuizMusic = (value: boolean, slideType: ModuleId) => {
  trackChangedSlideCustomization({
    customizationType: 'Advanced',
    action: 'Toggled quiz music',
    value: value ? 'On' : 'Off',
    slideType,
  });
};

export const trackVideoTimedUrl = (videoType: string | null) => {
  trackUser({
    event: 'Used timed video Url',
    properties: {
      'Question type': 'Video',
      'Video type': videoType,
    },
  });
};

export const trackConvertedToFreeText = (
  seriesId: string,
  slideType: string,
) => {
  trackUser({
    event: 'Converted to Text',
    properties: {
      placement: 'Edit',
      context: 'Edit view',
      slideType,
      seriesId,
    },
  });
};

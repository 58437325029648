import type { Series } from '@mentimeter/http-clients';
import type {
  SlideDeck as EditorSlideDeck,
  SeriesWithSlideDeck as EditorSeriesWithSlideDeck,
} from '@mentimeter/editor-schema/api-types-overrides';
import { isProxyInstance } from '../../../utils/is-proxy-instance';
import { baseSeriesCompatibilityLayerHandler } from '../../common/base-compatibility-layer-handler';
import { getMigratedEditorSeriesProperty } from './get-migrated-editor-series-property';

export const createEditorSeriesCompatibilityLayer = (
  target: Series | EditorSlideDeck | undefined,
): EditorSeriesWithSlideDeck | undefined => {
  if (!target) {
    return undefined;
  }

  if (isProxyInstance(target)) {
    return target as EditorSeriesWithSlideDeck;
  }

  return new Proxy(
    target,
    baseSeriesCompatibilityLayerHandler<EditorSlideDeck>({
      getMigratedSeriesProperty: getMigratedEditorSeriesProperty,
    }),
  ) as EditorSeriesWithSlideDeck;
};

import { Box } from '@mentimeter/ragnar-ui';

export const HeaderSeparator = () => (
  <Box
    height="16px"
    borderStyle="solid"
    borderLeftWidth={1}
    borderColor="borderWeak"
    mx={2}
  />
);

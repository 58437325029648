import { EyeIcon } from '@mentimeter/ragnar-visuals';
import { type IconButtonProps } from '@mentimeter/ragnar-ui';
import { HeaderIconButton } from './UI/HeaderIconButton';

export const HeaderPreviewButton = (
  props: Omit<IconButtonProps, 'id' | 'children'> & { label?: string },
) => {
  return (
    <HeaderIconButton
      data-testid="preview-button"
      id="preview-button"
      label={props.label || 'Preview'}
      {...props}
    >
      <EyeIcon color="inherit" />
    </HeaderIconButton>
  );
};

import React, { useMemo } from 'react';
import {
  type UserPresentationRole,
  type WorkspaceRole,
} from '@mentimeter/http-clients';
import { usePathname, LinkTabGeneric } from '@mentimeter/next-navigation';
import { buildPresentationPath } from '@mentimeter/presentation-url-utils';
import { Box, Text } from '@mentimeter/ragnar-ui';
import { useIdStore } from '@mentimeter/core-hooks/use-ids';
import {
  trackOpenEditView,
  trackShowResults,
} from '../../data-handlers/tracking';

interface HeaderTabsProps {
  seriesId: string;
  workspaceRole: WorkspaceRole;
  userRole: UserPresentationRole;
  workspaceId: number | null;
  viewOnly?: boolean;
}

export const HeaderTabs = ({
  seriesId,
  workspaceId,
  workspaceRole,
  userRole,
  viewOnly,
}: HeaderTabsProps) => {
  const pathname = usePathname();
  const activeTab = pathname.includes('results') ? 'results' : 'create';
  const questionId = useIdStore((state) => state.questionId);

  const tabActions = useMemo(
    () => [
      {
        key: 'create',
        title: 'Create',
        href: buildPresentationPath({
          seriesId,
          questionId,
          mode: 'edit',
        }),
        onClick: () => {
          trackOpenEditView(userRole);
        },
      },
      {
        key: 'results',
        title: 'Results',
        href: buildPresentationPath({ seriesId, mode: 'results' }),
        onClick: () => {
          trackShowResults({
            role: userRole,
            workspaceRole,
            isSharedPresentation: Boolean(workspaceId),
            seriesId,
          });
        },
        disabled: viewOnly,
      },
    ],
    [seriesId, userRole, workspaceId, workspaceRole, questionId, viewOnly],
  );

  return (
    <Box flexDirection="row" height="100%" mb="-space0.5" gap="space6">
      {tabActions.map((action) => (
        <LinkTabGeneric
          extend={() => ({
            pointerEvents: 'auto',
          })}
          id={action.key}
          key={action.key}
          active={action.key === activeTab}
          aria-label={action.title}
          aria-selected={action.key === activeTab}
          onClick={action.onClick}
          justifyContent="center"
          height="100%"
          href={action.href}
          prefetch
          disabled={action.disabled}
        >
          <Text
            color={action.disabled ? 'textDisabled' : 'text'}
            fontWeight="semiBold"
            lineHeight="125"
            fontSize="87.5"
            truncate
          >
            {action.title}
          </Text>
        </LinkTabGeneric>
      ))}
    </Box>
  );
};

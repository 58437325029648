import React, { useState } from 'react';
import {
  PopoverRoot,
  Dark,
  PopoverContainer,
  Box,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  Button,
  Text,
  PopoverAnchor,
  Light,
} from '@mentimeter/ragnar-ui';
import { formatDistanceStrict, format } from 'date-fns';
import { TrackingContext, TrackingPlacement } from '@mentimeter/http-clients';
import type { ReactNode } from 'react';
import {
  ENGAGEMENT_TYPE_NAME,
  ENGAGEMENT_COPY,
} from '@mentimeter/paywalls-data-hooks';
import { trackUser } from '@api/tracking/client';
import { TooltipTrigger } from '../../TooltipTrigger';
import { RequestUpgradeModal } from '../../../lib/request-upgrade';

export const EngagementLimitGracePeriodPopover = ({
  presentableUntil,
  seriesId,
  isFreeUserInPaid,
  children,
}: {
  presentableUntil: Date;
  seriesId: string;
  isFreeUserInPaid: boolean;
  children: ReactNode;
}) => {
  const [open, setOpen] = useState(true);
  const [dismissed, setDismissed] = useState(false);

  const presentableHours = formatDistanceStrict(presentableUntil, new Date(), {
    addSuffix: false,
  });

  return (
    <PopoverRoot
      open={open}
      onOpenChange={(newOpen) => {
        if (newOpen && dismissed) return;

        setOpen(newOpen);
        setDismissed(true);
        trackDismiss(seriesId);
      }}
    >
      <PopoverAnchor>
        <Box>
          <TooltipTrigger hideOnExit={false} onOpenChange={setOpen}>
            {children}
          </TooltipTrigger>
        </Box>
      </PopoverAnchor>
      <PopoverContainer width={344} data-testid="grace-period-popover">
        <Dark>
          <Box bg="surface">
            <PopoverHeader>
              {ENGAGEMENT_COPY.popover_grace_period.limit_reached}
            </PopoverHeader>
            <PopoverBody>
              <Box>
                <Text mt={2}>
                  You can still present this Menti for the next{' '}
                  <Text fontWeight="bold">{presentableHours}</Text> (until{' '}
                  {format(presentableUntil, 'h:mm aaa')}).
                  <br />
                </Text>

                <Text mt={1}>
                  After that, you&apos;ll need to upgrade to keep presenting.
                </Text>
              </Box>
            </PopoverBody>
            <PopoverFooter>
              {isFreeUserInPaid ? (
                <Light>
                  <RequestUpgradeModal
                    trackingContext={TrackingContext.Editview}
                    trackingPlacement={
                      TrackingPlacement.EngagementLimitsGracePeriod
                    }
                  >
                    <Button variant="positive">Request upgrade</Button>
                  </RequestUpgradeModal>
                </Light>
              ) : (
                <Button
                  variant="positive"
                  target="_blank"
                  href="/plans"
                  onClick={trackSeePlansClick}
                >
                  See plans
                </Button>
              )}
            </PopoverFooter>
          </Box>
        </Dark>
      </PopoverContainer>
    </PopoverRoot>
  );
};

const trackSeePlansClick = () => {
  trackUser({
    event: 'Plans from editor',
    properties: {
      context: 'Engagement limits grace period popover',
    },
  });
};

const trackDismiss = (seriesId: string) => {
  trackUser({
    event: 'Dismissed grace period popover',
    properties: {
      'engagement type': ENGAGEMENT_TYPE_NAME,
      context: TrackingContext.Editview,
      'series id': seriesId,
    },
  });
};

import React, { useState } from 'react';
import {
  Box,
  Dark,
  PopoverRoot,
  PopoverHeader,
  PopoverContainer,
  PopoverBody,
  PopoverFooter,
  PopoverTrigger,
} from '@mentimeter/ragnar-ui';
import { LocalStorage } from '@mentimeter/storage';
import { TrackingContext, TrackingPlacement } from '@mentimeter/http-clients';
import { useExperiment } from '@mentimeter/splitio';
import { EngagementLimitProgressComponent } from '../../index';
import { RequestUpgradePopover } from '../../../lib/request-upgrade';
import { TooltipTrigger } from '../../TooltipTrigger';
import { EngagementLimitUpgradeButton } from './EngagementLimitUpgradeButton';

const UPGRADE_TO_PRESENT_POPOVER_DISMISSED_KEY =
  'ENGAGEMENT_LIMIT_UPGRADE_TO_PRESENT_POPOVER_DISMISSED';

const PROGRESS_ELEMENT_WIDTH = '344px';
const PROGRESS_ELEMENT_TITLE = 'Upgrade to present now';

export function EngagementLimitUpgradeToPresentPopover({
  progress,
  limit,
  resetsAt,
  isFreeUserInPaid,
}: {
  progress: number;
  limit: number;
  resetsAt: Date;
  isFreeUserInPaid: boolean;
}) {
  const { isInExperiment: isInNewBannerExperiment } = useExperiment(
    'PUG_New_Limit_Experience',
    ['new-banner', 'modal'],
  );
  const hasDismissedBefore =
    LocalStorage.getItem(UPGRADE_TO_PRESENT_POPOVER_DISMISSED_KEY) === 'true';
  const [open, setOpen] = useState(
    !hasDismissedBefore && !isInNewBannerExperiment,
  );

  if (isFreeUserInPaid) {
    return (
      <RequestUpgradePopover
        trackingContext={TrackingContext.Editview}
        trackingPlacement={
          TrackingPlacement.EngagementLimitsPresentButtonPaywall
        }
      >
        <EngagementLimitUpgradeButton />
      </RequestUpgradePopover>
    );
  }

  if (isInNewBannerExperiment) {
    return <EngagementLimitUpgradeButton />;
  }

  return (
    <PopoverRoot
      open={open}
      onOpenChange={(newOpen) => {
        setOpen(newOpen);

        if (!newOpen) onDismiss();
      }}
    >
      <PopoverTrigger>
        <Box>
          <TooltipTrigger hideOnExit={false} onOpenChange={setOpen}>
            <EngagementLimitUpgradeButton />
          </TooltipTrigger>
        </Box>
      </PopoverTrigger>
      <PopoverContainer width={PROGRESS_ELEMENT_WIDTH}>
        <Dark>
          <Box bg="surface">
            <PopoverHeader>{PROGRESS_ELEMENT_TITLE}</PopoverHeader>
            <PopoverBody>
              <EngagementLimitProgressComponent
                progress={progress}
                limit={limit}
                resetsAt={resetsAt}
                showUpgradeBadge
              />
            </PopoverBody>
            <PopoverFooter></PopoverFooter>
          </Box>
        </Dark>
      </PopoverContainer>
    </PopoverRoot>
  );

  function onDismiss() {
    LocalStorage.setItem({
      type: 'functional',
      key: UPGRADE_TO_PRESENT_POPOVER_DISMISSED_KEY,
      value: 'true',
    });
  }
}

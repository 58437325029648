import React, { useState } from 'react';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useOnce } from '@mentimeter/react-hooks';
import { trackOpenPaywall } from '../tracking/track-open-paywall';
import { useIsRoleUpgradeEnabled } from '../hooks/use-role-upgrade-enabled';
import { RequestUpgradePopoverContent } from './popover/RequestUpgradePopoverContent';
import {
  REQUEST_UPGRADE_DESCRIPTION,
  REQUEST_UPGRADE_TITLE,
} from './constants';
import type { RequestUpgradeComponentProps } from './types';
import { requestUpgrade } from './requestUpgrade';
import { RoleUpgradeDisabledPopoverContent } from './popover/RoleUpgradeDisabledPopoverContent';
import { RoleUpgradeDisabledDataHandler } from './RoleUpgradeDisabledDataHandler';

export function RequestUpgradePopover({
  title = REQUEST_UPGRADE_TITLE,
  description = REQUEST_UPGRADE_DESCRIPTION,
  trackingContext,
  trackingPlacement,
  children,
}: RequestUpgradeComponentProps) {
  const [show, setShow] = useState(false);
  const { data: workspace } = useWorkspace();
  const isRoleUpgradeEnabled = useIsRoleUpgradeEnabled();

  useOnce(show, () => {
    onOpenPaywall();
  });

  if (!isRoleUpgradeEnabled) {
    return (
      <RoleUpgradeDisabledDataHandler workspaceId={workspace?.id}>
        {({ message }) => (
          <RoleUpgradeDisabledPopoverContent
            open={show}
            title={title}
            description={description}
            onOpenChange={setShow}
            message={message}
          >
            {children}
          </RoleUpgradeDisabledPopoverContent>
        )}
      </RoleUpgradeDisabledDataHandler>
    );
  }

  return (
    <RequestUpgradePopoverContent
      title={title}
      description={description}
      open={show}
      onOpenChange={setShow}
      onRequestUpgrade={(message) =>
        requestUpgrade({
          message,
          trackingContext,
          trackingPlacement,
        })
      }
    >
      {children}
    </RequestUpgradePopoverContent>
  );

  function onOpenPaywall() {
    trackOpenPaywall({
      trackingContext,
      trackingPlacement,
      upgradeType: 'Request upgrade',
    });
  }
}

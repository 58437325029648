import type { Slide as EditorSlide } from '@mentimeter/editor-schema/api-types-overrides';
import { commonSlidePropertyKeys } from '../../common/constants';

export const editorSlidePropertyKeys = [
  'slideId',
  'staticContent',
  'speakerNotes',
  'legacyQuestionAdminKey',
  'qrCodeVisibility',
  ...commonSlidePropertyKeys,
] as const satisfies Array<keyof EditorSlide>;

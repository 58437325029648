import { forwardRef } from 'react';
import { SettingsIcon } from '@mentimeter/ragnar-visuals';
import { type IconButtonProps } from '@mentimeter/ragnar-ui';
import { HeaderIconButton } from './UI/HeaderIconButton';

export const HeaderSettingsButton = forwardRef(
  (buttonProps: IconButtonProps & { label?: string }, forwardedRef) => {
    return (
      <HeaderIconButton
        ref={forwardedRef}
        size="compact"
        {...buttonProps}
        id="header-settings-button"
        variant="tertiary"
        borderRadius="full"
        label={buttonProps.label || 'Settings'}
      >
        <SettingsIcon color={buttonProps.disabled ? 'textDisabled' : 'text'} />
      </HeaderIconButton>
    );
  },
);

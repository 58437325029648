import { useWorkspace } from '@mentimeter/workspace-data-hooks';

export function useIsRoleUpgradeEnabled() {
  const { data: workspace } = useWorkspace();

  if (workspace?.requestRoleUpgradeEnabled === false) {
    return false;
  }

  return true;
}

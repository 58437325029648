import React, { forwardRef } from 'react';
import NextLink from 'next/link';
import { TabGeneric, type TabGenericProps } from '@mentimeter/ragnar-ui';
import {
  FLEX_CONTAINER,
  LAYOUT,
  POSITION,
  styled,
} from '@mentimeter/ragnar-styled';
import { rule } from './LinkTabGenericRule';
import { getRouteInternally } from './getRouteInternally';

export type LinkTabGenericProps =
  | TabGenericProps
  | StyledNextLinkTabGenericProps
  | StyledLinkTabGenericProps;

function isInternalLinkProps(
  props: LinkTabGenericProps,
): props is StyledNextLinkTabGenericProps {
  return (
    ('href' in props &&
      props.href !== undefined &&
      getRouteInternally(props.href).shouldRouteInternally) ||
    false
  );
}

function isExternalLinkProps(
  props: LinkTabGenericProps,
): props is StyledLinkTabGenericProps {
  return (
    ('href' in props &&
      props.href !== undefined &&
      !getRouteInternally(props.href).shouldRouteInternally) ||
    false
  );
}

export const LinkTabGeneric = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  LinkTabGenericProps
>((props, ref) => {
  if (isInternalLinkProps(props)) {
    const { href, prefetch, ...rest } = props;

    const { internalLink } = getRouteInternally(props.href);

    return (
      <StyledNextLinkTabGeneric
        display="flex"
        href={internalLink}
        ref={ref}
        flexDirection="column"
        {...rest}
      />
    );
  } else if (isExternalLinkProps(props)) {
    return <StyledLinkTabGeneric {...props} ref={ref} />;
  }
  return <TabGeneric {...props} ref={ref} />;
});

const StyledNextLinkTabGeneric = styled(
  NextLink,
  {
    displayName: 'LinkTabGeneric',
  },
  true,
)(rule, 'kosmosSpacing', 'color', ...LAYOUT, ...FLEX_CONTAINER, ...POSITION);

type StyledNextLinkTabGenericProps = React.ComponentProps<
  typeof StyledNextLinkTabGeneric
>;

const StyledLinkTabGeneric = styled(
  'a',
  {
    displayName: 'LinkTabGeneric',
  },
  true,
)(rule, 'kosmosSpacing', 'color', ...LAYOUT, ...FLEX_CONTAINER, ...POSITION);

type StyledLinkTabGenericProps = React.ComponentProps<
  typeof StyledLinkTabGeneric
>;

import { Badge, type BadgeT } from '@mentimeter/ragnar-ui';

type BadgeStyles = Pick<BadgeT, 'bg' | 'ml' | 'textTransform'>;
interface HeaderBadgeProps extends BadgeStyles {
  text: string;
  textColor?: string | undefined;
}

export const HeaderBadge = ({
  text,
  bg = 'infoWeakest',
  textColor = 'onInfoWeakest',
  ...rest
}: HeaderBadgeProps) => {
  return <Badge type="text" text={text} bg={bg} color={textColor} {...rest} />;
};

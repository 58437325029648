import { trackUser } from '@api/tracking/client';
import type { TrackingContext } from '@mentimeter/http-clients';

interface OpenPaywallTrackingProperties {
  trackingContext: TrackingContext | undefined;
  trackingPlacement: string;
  upgradeType: 'Request upgrade' | 'Self upgrade';
  paywallType?: 'Classic' | 'Recommend plan';
}

export function trackOpenPaywall({
  trackingContext,
  trackingPlacement,
  upgradeType,
  paywallType = 'Classic',
}: OpenPaywallTrackingProperties) {
  trackUser({
    event: 'Opened paywall',
    properties: {
      context: trackingContext,
      placement: trackingPlacement,
      'upgrade type': upgradeType,
      'paywall type': paywallType,
    },
  });
}

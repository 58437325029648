import { LocalStorage } from '@mentimeter/storage';
import { useState } from 'react';
import type { PresentTarget } from './usePresentActions';

const LAST_PRESENT_TARGET_KEY = 'last-present-target';

export const useLastPresentTarget = () => {
  const [lastTarget, _setLastTarget] = useState<PresentTarget>(
    (LocalStorage.getItem(LAST_PRESENT_TARGET_KEY) as PresentTarget) ??
      'internal',
  );

  const setLastTarget = (target: PresentTarget) => {
    LocalStorage.setItem({
      key: LAST_PRESENT_TARGET_KEY,
      value: target,
      type: 'functional',
    });
    _setLastTarget(target);
  };

  return [lastTarget, setLastTarget] as const;
};

import React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box, type BoxT } from '../Box';

export const PopoverFooter = ({ children, className, ...rest }: BoxT) => (
  <Box
    className={clsx(
      'w-full',
      'justify-end',
      'flex-wrap',
      'px-4',
      'pb-4',
      'gap-2',
      'flex-col-reverse',
      'sm:flex-row',
      'items-stretch',
      'sm:items-center',
      className,
    )}
    {...rest}
  >
    {children}
  </Box>
);

import { Button, type ButtonT } from '@mentimeter/ragnar-ui';
import { forwardRef } from 'react';

export const HeaderShareButton = forwardRef(
  (buttonProps: Omit<ButtonT, 'iconLeading' | 'children'>, ref) => {
    return (
      <Button size="compact" ref={ref} {...buttonProps}>
        Share
      </Button>
    );
  },
);

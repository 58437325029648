import React, { type ComponentProps } from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { Arrow } from '@radix-ui/react-dropdown-menu';
import { cn } from '@mentimeter/ragnar-tailwind-config';

const DropdownMenuArrowRadixStyled = styled(
  Arrow,
  {
    displayName: 'DropdownMenuArrowRadixStyled',
  },
  true,
)('color', 'kosmosSpacing');

export type DropdownMenuArrowProps = ComponentProps<
  typeof DropdownMenuArrowRadixStyled
>;

export const DropdownMenuArrow = React.forwardRef(
  (props: DropdownMenuArrowProps, ref) => {
    const { className, ...rest } = props;
    return (
      <DropdownMenuArrowRadixStyled
        ref={ref}
        className={cn('fill-[--color-surface]', className)}
        {...rest}
      />
    );
  },
);

import { Device } from '@mentimeter/ragnar-device';
import { Box, Button } from '@mentimeter/ragnar-ui';
import { CountdownIcon } from '@mentimeter/ragnar-visuals';
import { trackUser } from '@api/tracking/client';

const track = () => {
  trackUser({
    event: 'Plans from editor',
    properties: {
      context: 'Engagement limits present button',
    },
  });
};

export function EngagementLimitUpgradeButton() {
  return (
    <Box>
      <Button
        variant="positive"
        iconLeading={CountdownIcon}
        href="/plans"
        target="_blank"
        onClick={track}
      >
        <Device.Match lessThan={2}>Upgrade</Device.Match>
        <Device.Match greaterThan={1}>Upgrade to present</Device.Match>
      </Button>
    </Box>
  );
}

import { type DSC, designSystemConfig } from '@mentimeter/ragnar-dsc';

export const EDITOR_DSC: DSC = {
  ...designSystemConfig,
  fontSizes: [
    '0.625rem',
    '0.875rem',
    '1rem',
    '1.25rem',
    '1.5rem',
    '1.75rem',
    '2.25rem',
    '2.625rem',
    '3rem',
    '4.25rem',
  ],
  kosmosFontSize: {
    62.5: '0.625rem', // 10px
    75: '0.75rem', // 12px
    87.5: '0.875rem', // 14px
    100: '1rem', // 16px
    112.5: '1.125rem', // 18px
    125: '1.25rem', // 20px
    150: '1.5rem', // 24px
    175: '1.75rem', // 28px
    200: '2rem', // 32px
    225: '2.25rem', // 36px
    250: '2.5rem', // 40px
    300: '3rem', // 48px
    400: '4rem', // 64px
    500: '5rem', // 80px
    600: '6rem', // 96px
  },
  kosmosLineHeight: {
    ...designSystemConfig.kosmosLineHeight,
    fixed: {
      75: '0.75rem',
      100: '1rem',
      125: '1.25rem',
      150: '1.5rem',
      175: '1.75rem',
      200: '2rem',
      225: '2.25rem',
      250: '2.5rem',
    },
  },
  heights: ['2rem', '2.5rem', '3rem', '3.5rem', '4rem'],
  borders: [0, 1, 2],
};

import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';
import type { SidebarPaneId } from '@mentimeter/editor-essentials/types';

interface State {
  activeSidebarPane: SidebarPaneId;
  setActiveSidebarPane: (data: SidebarPaneId) => void;
}

const useState = createWithEqualityFn<State>(
  (set) => ({
    activeSidebarPane: 'initial',
    setActiveSidebarPane: (data: State['activeSidebarPane']) =>
      set((state) => ({ ...state, activeSidebarPane: data })),
  }),
  shallow,
);

export const useActiveSidebarPaneStore = (): [
  State['activeSidebarPane'],
  State['setActiveSidebarPane'],
] => {
  return useState((state) => [
    state.activeSidebarPane,
    state.setActiveSidebarPane,
  ]);
};

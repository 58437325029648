import { useState } from 'react';
import { useWorkspace } from '@mentimeter/workspace-data-hooks';
import { useOnce } from '@mentimeter/react-hooks';
import { trackOpenPaywall } from '../tracking/track-open-paywall';
import { useIsRoleUpgradeEnabled } from '../hooks/use-role-upgrade-enabled';
import type { RequestUpgradeComponentProps } from './types';
import { RequestUpgradeModalContent } from './modal/RequestUpgradeModalContent';
import { requestUpgrade } from './requestUpgrade';
import { RoleUpgradeDisabledModalContent } from './modal/RoleUpgradeDisabledModalContent';
import { RoleUpgradeDisabledDataHandler } from './RoleUpgradeDisabledDataHandler';

interface RequestUpgradeModalProps extends RequestUpgradeComponentProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  withTrigger?: boolean;
}

export function RequestUpgradeModal({
  title,
  description,
  trackingContext,
  trackingPlacement,
  open: openExternal,
  onOpenChange,
  onTrigger,
  withTrigger = true,
  children,
}: RequestUpgradeModalProps) {
  const [openInternal, setOpenInternal] = useState(false);
  const { data: workspace } = useWorkspace();
  const isRoleUpgradeEnabled = useIsRoleUpgradeEnabled();

  // We need to use the open prop to control the modal from the outside
  const open = openExternal || openInternal;
  const setOpen = (newState: boolean) => {
    setOpenInternal(newState);
    onOpenChange?.(newState);
  };

  useOnce(open, () => {
    onOpenPaywall();
  });

  if (!isRoleUpgradeEnabled) {
    return (
      <RoleUpgradeDisabledDataHandler workspaceId={workspace?.id}>
        {({ message }) => (
          <RoleUpgradeDisabledModalContent
            open={open}
            onOpenChange={setOpen}
            title={title}
            description={description}
            onTrigger={onTrigger}
            withTrigger={withTrigger}
            message={message}
          >
            {children}
          </RoleUpgradeDisabledModalContent>
        )}
      </RoleUpgradeDisabledDataHandler>
    );
  }

  return (
    <RequestUpgradeModalContent
      title={title}
      description={description}
      open={open}
      onOpenChange={setOpen}
      onRequestUpgrade={(message) =>
        requestUpgrade({
          message,
          trackingContext,
          trackingPlacement,
        })
      }
      withTrigger={withTrigger}
      onTrigger={onTrigger}
    >
      {children}
    </RequestUpgradeModalContent>
  );

  function onOpenPaywall() {
    trackOpenPaywall({
      trackingContext,
      trackingPlacement,
      upgradeType: 'Request upgrade',
    });
  }
}

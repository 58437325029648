import type { ReactNode } from 'react';
import { Box } from '@mentimeter/ragnar-ui';

export const Middle = ({ children }: { children: ReactNode }) => {
  return (
    <Box flexDirection="row" justifyContent="center" alignItems="center">
      {children}
    </Box>
  );
};

import { cva } from '@mentimeter/ragnar-tailwind-config';

export const variants = cva(['w-full', 'px-4', '-mb-2', 'pt-3'], {
  variants: {
    showDismiss: {
      true: '',
      false: '',
    },
    children: {
      true: '',
      false: '',
    },
  },
  compoundVariants: [
    {
      showDismiss: true,
      children: true,
      className: 'pt-8',
    },
    {
      showDismiss: true,
      children: false,
      className: 'pt-6',
    },
  ],
});

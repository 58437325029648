import type { PresentTarget } from './hooks/usePresentActions';

export const MISSING_LEADERBOARD_TITLE = 'The Quiz will not have a winner!';

export const MISSING_LEADERBOARD_BODY =
  'Reveal the winner of the Quiz by adding a leaderboard after the last question.';

export const MISSING_LEADERBOARD_ACTION_TEXT = 'Add leaderboard';

export const PRESENT_ACTION_TITLES: Record<PresentTarget, string> = {
  internal: 'Present',
  msteams: 'Present in Teams',
  withResults: 'Present with results',
};

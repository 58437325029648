import type { ReactNode } from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { useDevice } from '@mentimeter/ragnar-device';
import { Left } from './Left';
import { Right } from './Right';
import { Middle } from './Middle';

export const HeaderLayout = ({ children }: { children: ReactNode }) => {
  const { breakpoint } = useDevice();
  const height = breakpoint > 3 ? 4 : 3;

  return (
    <Box
      alignItems="stretch"
      id="header-layout"
      bg="bg"
      borderBottomWidth={1}
      borderColor="borderWeak"
      borderStyle="solid"
      className="ragnar-reset ragnar-reset--button-links"
      height={height}
      overflow="visible"
      pl="space2"
      pr="space3"
      width="100%"
      display="grid"
      flexDirection="row"
      gridTemplateColumns={['1fr auto auto', null, '1fr auto 1fr']}
    >
      {children}
    </Box>
  );
};

HeaderLayout.Left = Left;
HeaderLayout.Right = Right;
HeaderLayout.Middle = Middle;

import React, { useRef } from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import type { ReactNode } from 'react';

export function TooltipTrigger({
  onOpenChange,
  hideOnExit,
  children,
}: {
  onOpenChange: (newOpen: boolean) => void;
  hideOnExit: boolean;
  children: ReactNode;
}) {
  const showTimeout = useRef<NodeJS.Timeout | undefined>(undefined);
  const hideTimeout = useRef<NodeJS.Timeout | undefined>(undefined);

  return (
    <Box
      onMouseEnter={() => {
        clearTimeout(hideTimeout.current);
        showTimeout.current = setTimeout(() => {
          onOpenChange(true);
        }, 500);
      }}
      onMouseLeave={() => {
        clearTimeout(showTimeout.current);

        if (!hideOnExit) return;
        hideTimeout.current = setTimeout(() => {
          onOpenChange(false);
        }, 500);
      }}
    >
      {children}
    </Box>
  );
}

import React, { type ComponentProps } from 'react';
import { styled } from '@mentimeter/ragnar-styled';
import { Content } from '@radix-ui/react-dropdown-menu';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { menuContentDefaultClasses } from '../menuContentDefaultClasses';

const FelaDropdownMenuContent = styled(
  Content,
  {
    displayName: 'DropdownMenuContentRadixStyled',
  },
  true,
  [
    'loop',
    'forceMount',
    'side',
    'sideOffset',
    'align',
    'alignOffset',
    'avoidCollisions',
    'collisionBoundary',
    'collisionPadding',
    'arrowPadding',
    'sticky',
    'hideWhenDetached',
  ],
)('color', 'kosmosSpacing', 'layout', 'flexbox', 'position');

export type DropdownMenuContentProps = ComponentProps<
  typeof FelaDropdownMenuContent
>;

export const DropdownMenuContent = React.forwardRef(
  (props: DropdownMenuContentProps, forwardedRef) => {
    const { className, ...rest } = props;

    return (
      <FelaDropdownMenuContent
        ref={forwardedRef}
        loop
        collisionPadding={16}
        arrowPadding={4}
        sideOffset={1}
        className={cn(menuContentDefaultClasses, className)}
        {...rest}
      />
    );
  },
);

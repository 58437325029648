import {
  Box,
  Button,
  Markdown,
  ModalBody,
  ModalContainer,
  ModalDismiss,
  ModalFooter,
  ModalHeader,
  ModalRoot,
  Text,
} from '@mentimeter/ragnar-ui';
import type { ReactNode } from 'react';
import { PaywallModalTrigger } from '../../triggers/PaywallModalTrigger';
import {
  REQUEST_UPGRADE_DESCRIPTION,
  REQUEST_UPGRADE_TITLE,
} from '../constants';

export function RoleUpgradeDisabledModalContent({
  open,
  onOpenChange,
  withTrigger = true,
  onTrigger,
  children,
  title = REQUEST_UPGRADE_TITLE,
  description = REQUEST_UPGRADE_DESCRIPTION,
  message,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  withTrigger?: boolean;
  onTrigger?: (() => void) | undefined;
  children: ReactNode;
  description?: string | undefined;
  title?: string | undefined;
  message: string;
}) {
  function handleTrigger() {
    onOpenChange(true);
    onTrigger?.();
  }

  return (
    <ModalRoot modal open={open} onOpenChange={onOpenChange}>
      {withTrigger && (
        <PaywallModalTrigger trigger={handleTrigger}>
          {children}
        </PaywallModalTrigger>
      )}

      {!withTrigger && children}

      <ModalContainer
        data-testid="request-upgrade-disabled-modal"
        width="480px"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalHeader title={title}>{title}</ModalHeader>
        <ModalBody>
          <Text mb={2}>{description}</Text>
          <Box color="textWeak">
            <Markdown>{message}</Markdown>
          </Box>
        </ModalBody>
        <ModalFooter>
          <ModalDismiss>
            <Button variant="primary">Got it</Button>
          </ModalDismiss>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
}

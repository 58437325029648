export const menuContentDefaultClasses = [
  'bg-surface',
  'rounded-lg',
  'drop-shadow-menu',
  'p-4',
  'flex',
  'flex-col',
  'gap-0.5',
  'z-modal',
  'max-w-[calc(100vw-theme(spacing.8))]',
  'focus:outline-none',
  'data-[side="bottom"]:motion-safe:animate-fade-in-bottom',
  'data-[side="top"]:motion-safe:animate-fade-in-top',
  'data-[side="left"]:motion-safe:animate-fade-in-left',
  'data-[side="right"]:motion-safe:animate-fade-in-right',
];

import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { Series } from '@mentimeter/http-clients';
import { SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_RESPONSES_BLOCKED } from '../../../constants';

export const participationModeSettingsToSeriesClosedForVoting = (
  participationSettings: EditorSlideDeck['participationSettings'] | undefined,
): Series['closed_for_voting'] => {
  return (
    participationSettings?.participationResponseMode ===
    SLIDE_DECK_PARTICIPATION_RESPONSE_MODE_RESPONSES_BLOCKED
  );
};

import type { IconButtonProps } from '@mentimeter/ragnar-ui';
import type { InternalPresentTrackingPlacements } from '@mentimeter/editor-tracking';
import type { PresentTarget } from '../data-handlers/HeaderPresentDataHandler/hooks/usePresentActions';
import { PRESENT_ACTION_TITLES } from '../data-handlers/HeaderPresentDataHandler/constants';
import { HeaderCta } from './HeaderCta';

export const HeaderPresentButton = ({
  buttonReferenceId,
  persistingTargetEnabled,
  handlePresentInternal,
  handleOnPresentClick,
  lastTarget,
  size,
  disabled,
}: {
  buttonReferenceId: string;
  persistingTargetEnabled: boolean;
  handlePresentInternal?: (
    placement: InternalPresentTrackingPlacements,
  ) => void;
  handleOnPresentClick?: (target: PresentTarget) => void;
  lastTarget: PresentTarget;
  size?: IconButtonProps['size'];
  disabled?: boolean;
}) => (
  <HeaderCta.Button
    id={buttonReferenceId}
    data-testid="present-button"
    aria-label="Present this presentation"
    variant="brand"
    onClick={() => {
      if (!persistingTargetEnabled)
        return handlePresentInternal?.('Present button header');

      handleOnPresentClick?.(lastTarget);
    }}
    disabled={disabled}
    size={size}
  >
    {persistingTargetEnabled
      ? PRESENT_ACTION_TITLES[lastTarget]
      : PRESENT_ACTION_TITLES.internal}
  </HeaderCta.Button>
);

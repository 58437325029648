import { Device } from '@mentimeter/ragnar-device';
import { HeaderBadge } from '../HeaderBadge';

interface HeaderSurveyBadgeProps {
  isSurvey: boolean;
}

export const HeaderSurveyBadge = ({ isSurvey }: HeaderSurveyBadgeProps) => {
  if (!isSurvey) {
    return null;
  }
  return (
    <Device.Match greaterThan={2}>
      <HeaderBadge text="Survey" ml="space2" />
    </Device.Match>
  );
};

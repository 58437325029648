import type {
  QuestionWithSlide as EditorQuestionWithSlide,
  Slide as EditorSlide,
} from '@mentimeter/editor-schema/api-types-overrides';
import type { Question } from '@mentimeter/http-clients';
import { isProxyInstance } from '../../../utils/is-proxy-instance';
import { getCommonSlideCompatibilityHandler } from '../../common/get-common-slide-compatibility-handler';
import { editorSlidePropertyKeys } from './constants';
import { mapEditorSlidePropertyToQuestionProperty } from './map-editor-slide-property-to-question-property';

export function createEditorSlideCompatibilityLayer(
  target: Question | EditorSlide,
): EditorQuestionWithSlide {
  if (isProxyInstance(target)) {
    return target as EditorQuestionWithSlide;
  }

  return new Proxy(
    target,
    getCommonSlideCompatibilityHandler(
      editorSlidePropertyKeys,
      mapEditorSlidePropertyToQuestionProperty,
    ),
  ) as EditorQuestionWithSlide;
}

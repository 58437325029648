import type { SlideDeck as EditorSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import type { Series } from '@mentimeter/http-clients';

export const participationSettingsToSeriesParticipationIdentityModePolicy = (
  participationSettings: EditorSlideDeck['participationSettings'] | undefined,
): Series['participation_identity_mode_policy'] => {
  return participationSettings?.participationIdentityModePolicy === 'enabled'
    ? 'enabled'
    : 'disabled_by_workspace_setting';
};

import {
  useState,
  useEffect,
  useRef,
  type FocusEventHandler,
  type JSX,
} from 'react';
import {
  Box,
  Clickable,
  ScreenReaderOnly,
  Text,
  TransparentInput,
} from '@mentimeter/ragnar-ui';
import { useConstant } from '@mentimeter/react-hooks';
import debounce from 'lodash/debounce';
import { addUnit } from '@mentimeter/ragnar-utils';

const MIN_INPUT_WIDTH = 100;
const HEADER_MAX_WIDTH = 320;

interface HeaderTitleProps {
  seriesName: string | undefined;
  onTitleChanged: (value: string) => void;
  locationDetails: {
    name: string | null;
    icon: JSX.Element;
  };
  onClickLocation: () => void;
}

export const HeaderTitle = ({
  seriesName,
  onTitleChanged,
  locationDetails,
  onClickLocation,
}: HeaderTitleProps) => {
  const [name, setName] = useState(seriesName);
  const [inputWidth, setInputWidth] = useState<number>(MIN_INPUT_WIDTH);
  const [showMentiLocation, setShowMentiLocation] = useState(true);

  useEffect(() => {
    setName(seriesName);
  }, [seriesName]);

  const debouncedOnChange = useConstant(() => debounce(onTitleChanged, 1000));

  const onChangeHandler = (name: string) => {
    // don't save empty title while typing so default title returned from backend doesn't interrupt user
    if (name) {
      debouncedOnChange(name);
    }
    setName(name);
  };

  const textRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    setInputWidth((textRef.current?.clientWidth || 0) + 5);
  }, [name]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>): void => {
    if (e.key === 'Enter') {
      const node = document.querySelector<HTMLInputElement>(
        '#editor-presentation-title',
      );
      if (node) node.blur();
    }
  };

  const handleFocus = () => setShowMentiLocation(false);

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    setShowMentiLocation(true);
    // save empty title on blur instead of while typing
    if (!e.currentTarget.value) debouncedOnChange('');
  };

  return (
    <>
      {name && (
        <Text as="h1">
          <ScreenReaderOnly>{name}</ScreenReaderOnly>
        </Text>
      )}
      <Box
        borderStyle="solid"
        borderWidth="2px"
        borderColor="transparent"
        extend={({ theme }) => ({
          // as wide as it needs to be for title input text to fit
          // but within min and max value and able to shrink if not enough space
          flex: `0 1 ${inputWidth + theme.kosmosSpacing.space4}px`,
          minWidth: `${MIN_INPUT_WIDTH + theme.kosmosSpacing.space4}px`,
          maxWidth: `${HEADER_MAX_WIDTH + theme.kosmosSpacing.space4}px`,
        })}
      >
        <TransparentInput
          aria-label="Presentation name"
          name="title"
          id="editor-presentation-title"
          value={name}
          py={showMentiLocation ? 'space0.5' : 'space2'}
          onChange={(e) => onChangeHandler(e.currentTarget.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyPress={handleKeyPress}
          extend={({ theme }) => ({
            width: '100%',
            padding: `${theme.kosmosSpacing.space2}px`,
            textOverflow: 'ellipsis',
            '&:hover': {
              paddingLeft: addUnit(theme.kosmosSpacing.space2),
            },
            '&:focus': {
              padding: `${theme.kosmosSpacing.space2}px`,
              paddingLeft: addUnit(theme.kosmosSpacing.space2),
            },
          })}
        />
        <Clickable
          maxWidth="100%"
          px="space2"
          py="space0"
          my="space0"
          color="textWeaker"
          data-testid={`presentation-location-${locationDetails.name}`}
          onClick={onClickLocation}
          flexDirection="row"
          alignItems="center"
          extend={() => ({
            height: showMentiLocation ? 'auto' : '0px',
            opacity: showMentiLocation ? 1 : 0,
            transition: 'none',
            '&:focus-visible': {
              'outline-offset': '0px',
            },
          })}
        >
          <Box width="auto" aria-hidden>
            {locationDetails.icon}
          </Box>
          <ScreenReaderOnly>Located in</ScreenReaderOnly>
          <Text
            ml={1}
            fontWeight="regular"
            color="textWeaker"
            truncate
            fontSize="75"
            maxWidth="100%"
          >
            {locationDetails.name}
          </Text>
        </Clickable>
      </Box>
      <Box position="absolute" extend={() => ({ pointerEvents: 'none' })}>
        <Text
          ref={textRef}
          fontWeight="semiBold"
          fontSize={1}
          extend={() => ({ visibility: 'hidden' })}
        >
          {name}
        </Text>
      </Box>
    </>
  );
};

import React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { styled } from '@mentimeter/ragnar-styled';
import { Separator } from '@radix-ui/react-dropdown-menu';
import type { ComponentProps } from 'react';
import { menuSeparatorDefaultClasses } from '../menuSeparatorDefaultClasses';

const DropdownMenuSeparatorRadixStyled = styled(
  Separator,
  {
    displayName: 'DropdownMenuSeparatorRadixStyled',
  },
  true,
)('color', 'kosmosSpacing', 'layout');

export type DropdownMenuSeparatorProps = ComponentProps<
  typeof DropdownMenuSeparatorRadixStyled
>;

export const DropdownMenuSeparator = React.forwardRef(
  (props: DropdownMenuSeparatorProps, forwardedRef) => {
    const { className, ...rest } = props;
    return (
      <DropdownMenuSeparatorRadixStyled
        ref={forwardedRef}
        className={cn(menuSeparatorDefaultClasses, 'my-2', className)}
        {...rest}
      />
    );
  },
);

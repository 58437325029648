import type { ReactNode } from 'react';
import { Box } from '@mentimeter/ragnar-ui';

export const Left = ({ children }: { children: ReactNode }) => {
  return (
    <Box flexDirection="row" alignItems="center" py="space2">
      {children}
    </Box>
  );
};

import React from 'react';

interface ResultBox<T> {
  v: T;
}

/** A wrapper around a function that should stay constant through re-renders.
 * @example useConstant(() => debounce(update, 1000)) // the debounced function will not change even if update changes
 */
export function useConstant<T>(fn: () => T): T {
  const ref = React.useRef<ResultBox<T> | void | null>(null);

  if (!ref.current) {
    ref.current = { v: fn() };
  }

  return ref.current.v;
}

import React from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { styled } from '@mentimeter/ragnar-styled';
import { Item } from '@radix-ui/react-dropdown-menu';
import {
  menuItemVariants,
  type MenuItemVariantProps,
} from '../MenuItem.variants';

const DropdownMenuItemRadixStyled = styled(
  Item,
  {
    displayName: 'DropdownMenuItemRadixStyled',
  },
  true,
  ['asChild', 'disabled', 'textValue'],
)('color', 'kosmosSpacing', 'layout', 'flexbox');

export type DropdownMenuItemProps = React.ComponentProps<
  typeof DropdownMenuItemRadixStyled
> & {
  variant?: MenuItemVariantProps['variant'];
};

export const DropdownMenuItem = React.forwardRef(
  (props: DropdownMenuItemProps, ref) => {
    const { className, variant, ...rest } = props;
    return (
      <DropdownMenuItemRadixStyled
        className={cn(menuItemVariants({ variant }), className)}
        ref={ref}
        {...rest}
      />
    );
  },
);

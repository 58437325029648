import React, { forwardRef } from 'react';
import {
  FLEX_CONTAINER,
  LAYOUT,
  POSITION,
  styled,
} from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';

const FelaTabGeneric = styled('button', {
  displayName: 'TabGeneric',
})('kosmosSpacing', 'color', ...LAYOUT, ...FLEX_CONTAINER, ...POSITION);

export interface TabGenericProps
  extends React.ComponentProps<typeof FelaTabGeneric> {
  active?: boolean | undefined;
  disabled?: boolean | undefined;
  direction?: 'row' | 'column';
  compact?: boolean;
}

export const TabGeneric = forwardRef<
  HTMLAnchorElement | HTMLButtonElement | null,
  TabGenericProps
>((props, ref) => {
  const {
    className,
    active,
    direction = 'row',
    compact,
    disabled,
    ...rest
  } = props;

  const defaultClasses = [
    'r-box', //reset selector
    'flex',
    'flex-col',
    'flex-none',
    'items-start',
    'w-auto',
    'relative',
    'cursor-pointer',
    'm-[-1px]',
    'appearance-none',
    'no-underline',
    'bg-transparent',
    'transition-all',
    'text-primary',
    'hover:shadow-[inset_0_-3px_0_theme(borderColor.neutral.weakest)]',
    'focus:shadow-[inset_0_-3px_0_theme(colors.secondary.DEFAULT)]',
    'focus-visible:shadow-[inset_0_-2px_0_theme(colors.secondary.DEFAULT)]',
    'focus-visible:outline',
    'focus-visible:outline-4',
    'focus-visible:outline-offset-2',
    'focus-visible:outline-interactive-focused',
    'active:shadow-[inset_0_-3px_0_theme(colors.secondary.DEFAULT)]',
    'active:hover:shadow-[inset_0_-3px_0_theme(colors.neutral.weakest)]',
    'disabled:pointer-events-none',
    compact ? 'leading-100 py-2' : 'leading-125 py-4',
    direction === 'row' ? 'text-center' : 'text-left',
    active &&
      'shadow-[inset_0_-3px_0_theme(colors.secondary.DEFAULT)] hover:shadow-[inset_0_-3px_0_theme(colors.secondary.DEFAULT)]',
  ];

  return (
    <FelaTabGeneric
      disabled={disabled}
      className={cn(defaultClasses, className)}
      role="tab"
      {...rest}
      ref={ref}
    />
  );
});

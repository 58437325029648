import type { Question } from '@mentimeter/http-clients';
import type { Slide as EditorSlide } from '@mentimeter/editor-schema/api-types-overrides';
import { getSlideProperty as getCommonSlideProperty } from '../../../forward-compatibility/get-slide-property';
import type { CompatibilitySlide } from '../../../compatibility-types';

export const mapEditorSlidePropertyToQuestionProperty = (
  question: Question,
  key: keyof EditorSlide,
) => {
  switch (key) {
    case 'slideId':
      return question.id;

    case 'legacyQuestionAdminKey':
      return question.id;

    case 'speakerNotes': {
      return question.speaker_notes ?? '';
    }

    case 'hasComments':
      return question.has_comments;

    default:
      return getCommonSlideProperty(question, key as keyof CompatibilitySlide);
  }
};

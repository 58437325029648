/* eslint-disable menti-react/filename-convention--jsx */
import { LoaderIcon, CheckIcon, CrossIcon } from '@mentimeter/ragnar-visuals';
import type { GroupActionT } from '@mentimeter/ragnar-ui';
import type { EditorErrors } from '@mentimeter/core-global-state';

type StatesT = 'expired' | 'loading' | 'error' | 'connectionError' | 'idle';

export const STATUS_MAP: {
  [key in StatesT]: GroupActionT & {
    icon: React.ReactNode;
    iconBg?: string;
    message: string;
    errorTitle?: string;
    errorMessage?: string;
    errorDescription?: string;
    showIndication?: boolean;
  };
} = {
  idle: {
    icon: <CheckIcon size={2} color="onPositiveWeak" />,
    iconBg: 'positiveWeak',
    message: 'Saved',
    showIndication: true,
  },
  loading: {
    icon: <LoaderIcon size={2} color="text" />,
    message: 'Saving...',
    showIndication: true,
  },
  error: {
    icon: <CrossIcon size={2} color="onNegativeWeak" />,
    iconBg: 'negativeWeak',
    message: 'Error',
    errorTitle: 'Something happened...',
    errorMessage: 'Reload to try again.',
    errorDescription:
      'If the issues persist, contact [support](mailto:hello@mentimeter.com).',
    actions: [
      {
        key: 'reload',
        onClick: () => window.location.reload(),
        children: 'Reload',
      },
    ],
  },
  connectionError: {
    icon: <CrossIcon size={2} color="onNegativeWeak" />,
    iconBg: 'negativeWeak',
    message: 'Connection error',
    errorTitle: '',
    errorMessage: 'Reload to continue editing.',
    errorDescription:
      'Check your internet connection. If the problem persists, check the [requirements](/requirements) and contact your local IT support.',
    actions: [
      {
        key: 'reload',
        onClick: () => window.location.reload(),
        children: 'Reload',
      },
    ],
  },
  expired: {
    icon: <CrossIcon size={2} color="onNegativeWeak" />,
    iconBg: 'negativeWeak',
    message: 'Error',
    errorMessage:
      'You have been disconnected from the Mentimeter server for too long.',
    actions: [
      {
        key: 'reload now',
        onClick: () => window.location.reload(),
        children: 'Reload now',
      },
    ],
  },
};

export const ERROR_MAP: {
  [key in EditorErrors]: string;
} = {
  createRatingGridFromFile: 'creating rating grid from file',
  updateCroppedRatingGrid: 'updating cropped rating grid',
  updateQuestionRequest: 'updating question',
  archiveActiveQuestionResults: 'archiving question results',
  removeQuestions: 'removing questions',
  copyQuestion: 'copying question',
  copyCoreQuestions: 'copying questions',
  addQuestions: 'adding questions',
  updateQuestionType: 'updating question type',
  updateQuestionsVisibility: 'updating question visibility',
  updateSeries: 'updating presentation',
  updateSegmentations: 'updating segmentations',
  addExample: 'adding example',
};

import type { Question } from '@mentimeter/http-clients';
import type { QuestionWithSlide } from '@mentimeter/editor-schema/api-types-overrides';
import { createEditorSlideCompatibilityLayer } from '@mentimeter/compatibility/editor';
import { isLeaderboardSlide } from './questionFilters';

const uuid7VersionRe = /^.{14}7/;

export const getSlideById = <T extends Question>(
  slideId: string,
  slides: T[],
) =>
  [
    slides.find(
      (s) =>
        (s as unknown as QuestionWithSlide).slideId === slideId ||
        s.id === slideId,
    ),
    slides.findIndex(
      (s) =>
        (s as unknown as QuestionWithSlide).slideId === slideId ||
        s.id === slideId,
    ),
  ] as const;

export const getFollowingLeaderboardId = (
  questions: Question[],
  questionId: string,
): string | undefined => {
  const questionIndex = questions.findIndex((q) => q.id === questionId) || -1;
  if (
    questionIndex > -1 &&
    isLeaderboardSlide(questions[questionIndex + 1]?.type)
  ) {
    return questions[questionIndex + 1]?.id;
  }
  return undefined;
};

export const getSlideProxy = (
  slideId: string | undefined | null,
  questions: Array<QuestionWithSlide> | undefined,
): QuestionWithSlide | undefined => {
  if (!slideId || !questions) {
    return undefined;
  }
  let question: QuestionWithSlide | undefined;
  if (uuid7VersionRe.test(slideId)) {
    question = questions.find((q) => q?.slideId === slideId);
  } else {
    question = questions.find((q) => q.id === slideId);
  }
  if (question) {
    return createEditorSlideCompatibilityLayer(question);
  }
  return undefined;
};

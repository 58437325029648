import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { TextInputItem, type TextInputItemT } from '../Input/TextInput';

export const TransparentInput = React.forwardRef<
  HTMLInputElement,
  TextInputItemT
>(({ className, ...rest }, ref) => {
  const defaultClasses = [
    'min-w-100',
    'bg-transparent',
    'pl-0',
    'hover:pl-2',
    'focus:pl-2',
    'enabled:shadow-none',
    'transition-padding',
    'duration-75',
  ];

  return (
    <TextInputItem
      ref={ref}
      inputSize="compact"
      {...rest}
      className={clsx(defaultClasses, className)}
      extend={({ theme }) => ({
        ...(rest.extend ? rest.extend({ theme }) : {}),
      })}
    />
  );
});

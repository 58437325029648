import {
  type GetCollaboratorsResponse,
  getCollaboratorsRequest,
  getCollaboratorsResponse,
} from '@core-api/collaboration/collaboration/series/{series_id}/collaborators';
import { type SeriesCollaborator } from '@core-api/collaboration/types/response';
import userCache from '@mentimeter/user';
import { captureException, MentiError } from '@mentimeter/errors/sentry';
import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';

const getSeriesCollaborationCacheKey = (seriesId: string) =>
  seriesId ? `/collaboration/series/${seriesId}/collaborators` : null;

export const mutateSeriesCollaborators = async (seriesId: string) => {
  await mutate(getSeriesCollaborationCacheKey(seriesId));
};

export const useSeriesCollaborators = (
  seriesId: string,
): {
  collaborators: SeriesCollaborator[];
  isLoading: boolean;
  error: any;
} => {
  const { data, isLoading, error } = useSWR<GetCollaboratorsResponse>(
    getSeriesCollaborationCacheKey(seriesId),
    async () => {
      const region = userCache.region;
      const sessionToken = userCache.getToken();

      const response = await fetch(
        getCollaboratorsRequest({
          seriesId,
          region,
          userAuth: sessionToken,
        }),
      );

      if (!response.ok) {
        const err = new MentiError('error fetching collaborators', {
          feature: 'share-invite-modal',
          cause: error,
        });
        captureException(err);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return getCollaboratorsResponse(response);
    },
    {
      revalidateOnFocus: false,
    },
  );

  const collaborators = useMemo(() => data?.data || [], [data]);

  return {
    collaborators,
    isLoading,
    error,
  };
};

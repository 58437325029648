import { useMatch } from '@mentimeter/ragnar-device';
import { Button, type ButtonT } from '@mentimeter/ragnar-ui';

export function RequestUpgradeButton(buttonProps: ButtonT) {
  const isMobile = useMatch({ lessThan: 2 });

  return (
    <Button {...buttonProps}>{isMobile ? 'Upgrade' : 'Request upgrade'}</Button>
  );
}

'use client';
import { Box } from '@mentimeter/ragnar-ui';
import { type ReactNode } from 'react';
import '../container.css';

interface PageLayoutProps {
  elementId: string;
  header: ReactNode;
  content: ReactNode;
}

export function PageLayout({ elementId, header, content }: PageLayoutProps) {
  return (
    <Box id={elementId}>
      <Box as="header" width="100%">
        {header}
      </Box>

      <Box flex="1 1 auto" width="100%">
        {content}
      </Box>
    </Box>
  );
}

import type { ReactNode } from 'react';
import React from 'react';
import {
  EngagementStatus,
  useEngagementLimits,
} from '@mentimeter/paywalls-data-hooks';
import { getIsMemberLite, useUser } from '@mentimeter/user';
import { EngagementLimitGracePeriodPopover } from '../grace-period/EngagementLimitGracePeriodPopover';
import { EngagementLimitUpgradeToPresentPopover } from './EngagementLimitUpgradeToPresentPopover';

export function EngagementLimitPresentPaywallDataHandler({
  seriesId,
  children,
}: {
  seriesId: string;
  children: ReactNode;
}) {
  const engagementLimits = useEngagementLimits();
  const { user } = useUser();
  const isFreeUserInPaid = getIsMemberLite(user);

  if (!engagementLimits || !user) return children;

  const {
    engagementStatus,
    engagementData: { limit, progress, resetsAt, gracePeriod },
  } = engagementLimits;

  const hasGracePeriodForThisSeries = gracePeriod?.presentationId === seriesId;
  if (hasGracePeriodForThisSeries) {
    return (
      <EngagementLimitGracePeriodPopover
        seriesId={seriesId}
        presentableUntil={gracePeriod.presentableUntil}
        isFreeUserInPaid={isFreeUserInPaid}
      >
        {children}
      </EngagementLimitGracePeriodPopover>
    );
  }

  const hasReachedEngagementLimit =
    engagementStatus === EngagementStatus.ReachedLimit;

  if (hasReachedEngagementLimit) {
    return (
      <EngagementLimitUpgradeToPresentPopover
        progress={progress}
        limit={limit}
        resetsAt={resetsAt}
        isFreeUserInPaid={isFreeUserInPaid}
      />
    );
  }

  return children;
}

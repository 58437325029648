import { trackUser } from '@api/tracking/client';
import {
  gtmTrack,
  TrackingMethods as GTMTracking,
} from '@mentimeter/google-tracking';
import {
  type ModuleId,
  TrackingContext,
  type UserFeaturesType,
  type UserPresentationRole,
  type WorkspaceRole,
} from '@mentimeter/http-clients';
import {
  trackAddedImageOnOption,
  trackAddedOption,
  trackAddImageClicked,
  trackModifiedOptionText,
  trackOpenedSegmentation,
  trackRemovedOption,
  trackSwitchedSegmentation,
  trackToggledCorrectArea,
  trackUpdatedSegmentation,
} from './editPane';
import type {
  AddSlidePlacement,
  CancelAddSlideTrigger,
  ClickedQuestionTypeTrackingPlacement,
  HelpTab,
  PresentButtonPlacement,
  StartStateQuickAction,
  StartStateTrigger,
  TrackClickUpgradePayload,
} from './types';

export function trackBackToPresentation({
  role,
}: {
  role: UserPresentationRole;
}) {
  trackUser({
    event: 'Back to my presentations',
    properties: {
      placement: 'Overview',
      context: TrackingContext.Editview,
      role,
    },
  });
}

export function trackViewedEditView({
  role,
  context,
  workspaceRole,
  seriesId,
  isSharedPresentation,
  hasResults,
  inviteSource,
}: {
  role: UserPresentationRole;
  context: TrackingContext;
  workspaceRole: WorkspaceRole;
  seriesId: string;
  isSharedPresentation: boolean;
  hasResults: boolean;
  inviteSource: string | null;
}) {
  trackUser({
    event: 'Viewed edit view',
    properties: {
      role,
      context,
      'workspace role': workspaceRole,
      'series id': seriesId,
      'shared presentation': isSharedPresentation,
      'has results': hasResults,
      permission: 'collaborator',
      'invite source': inviteSource,
    },
  });
}

export function trackClickedAvatar({ role }: { role: UserPresentationRole }) {
  trackUser({
    event: 'Clicked on avatar',
    properties: {
      placement: 'Overview',
      context: TrackingContext.Editview,
      role,
    },
  });
}

export function trackClickedShareModalTab({
  paceMode,
  seriesId,
  tabKey,
  context,
}: {
  paceMode: string;
  seriesId: string;
  tabKey: string;
  context: TrackingContext;
}) {
  trackUser({
    event: 'Clicked share menu tab',
    properties: {
      'series id': seriesId,
      pace: paceMode,
      tab: tabKey,
      context,
    },
  });
}

export function trackClickedQuestionType({
  action,
  placement,
  moduleId,
}: {
  action: 'Change type' | 'Add new slide';
  placement: ClickedQuestionTypeTrackingPlacement;
  moduleId: ModuleId;
}) {
  trackUser({
    event: 'Question type clicked',
    properties: {
      context: TrackingContext.Editview,
      action,
      placement,
      'slide type': moduleId,
      version: 2,
    },
  });
}

export function trackCopiedSlideHotkey({
  role,
  amount,
  total,
}: {
  role: UserPresentationRole;
  amount: number;
  total: number;
}) {
  trackUser({
    event: 'Copied slide to presentation',
    properties: {
      placement: 'Hotkey paste',
      context: TrackingContext.Editview,
      role,
      amount,
      'presentation total slides amount': total,
    },
  });
}

export const trackOpenShareModal = ({
  context,
  placement,
  isSharedPresentation,
  open,
  seriesId,
}: {
  context: TrackingContext;
  placement: string | undefined;
  isSharedPresentation: boolean;
  open: boolean;
  seriesId: string;
}) => {
  trackUser({
    event: open ? 'Opened share menu' : 'Closed share menu',
    properties: {
      context,
      placement,
      'shared presentation': isSharedPresentation,
      'series id': seriesId,
    },
  });
};

interface TrackResetPresentationResultsBaseParams {
  seriesId: string;
  participants: number;
  role: UserPresentationRole;
  context: TrackingContext;
}

type TrackResetPresentationResultsFromMenuParams =
  TrackResetPresentationResultsBaseParams & {
    placement: 'Menu';
    amount: number;
  };

type TrackResetPresentationResultsFromCanvasOrChevronParams =
  TrackResetPresentationResultsBaseParams & {
    placement: 'Canvas' | 'Chevron';
  };

type TrackResetPresentationResultsParams =
  | TrackResetPresentationResultsFromMenuParams
  | TrackResetPresentationResultsFromCanvasOrChevronParams;

export function trackResetPresentationResults(
  params: TrackResetPresentationResultsParams,
) {
  const { seriesId, participants, placement, role, context } = params;
  const amount = params.placement === 'Menu' ? params.amount : undefined;

  trackUser({
    event: 'Reset presentation results',
    properties: {
      placement,
      role,
      amount,
      'series id': seriesId,
      participants,
      context,
    },
  });
}

type TrackResetSlideResultsParams =
  | {
      placement: 'Menu';
      amount: number;
      role: UserPresentationRole;
    }
  | { placement: 'Canvas'; role: UserPresentationRole };

export function trackResetSlideResults(params: TrackResetSlideResultsParams) {
  const { placement, role } = params;
  trackUser({
    event: 'Reset slide results',
    properties: {
      placement,
      context: TrackingContext.Editview,
      role,
      amount: 'amount' in params ? params.amount : undefined,
    },
  });
}

export function trackClickedKnowledgeHub() {
  trackUser({
    event: 'Clicked knowledge hub icon',
    properties: {
      context: TrackingContext.Editview,
      placement: 'Knowledge hub icon',
    },
  });
}

export function trackOpenedHelpTab({ tab }: { tab: HelpTab }) {
  trackUser({
    event: 'Opened help tab',
    properties: {
      context: TrackingContext.Editview,
      placement: 'Knowledge hub menu',
      tab,
    },
  });
}

interface TrackNavigateToUpgradeEventPayload {
  event: TrackClickUpgradePayload['event'];
  properties: Pick<TrackClickUpgradePayload['properties'], 'context' | 'plan'>;
}

export const trackClickUpgrade = (
  seriesOwnerId: number,
  userId: number,
  workspaceId: number | null,
  numberOfActiveTeamMembers: number | undefined,
  userFeatureType: UserFeaturesType,
  context: string,
): void => {
  gtmTrack({
    event: 'clickInteraction',
    type: 'upgradeFromApp',
  });

  const seriesRole: UserPresentationRole =
    seriesOwnerId === userId ? 'Owner' : 'Collaborator';

  trackUser({
    event: 'Plans from editor',
    properties: {
      context,
      'active team members': numberOfActiveTeamMembers || 0,
      'current plan': userFeatureType,
      role: seriesRole,
      'presentation owner': seriesRole === 'Owner',
      'shared presentation': Boolean(workspaceId),
    },
  });
};

interface TrackNavigateToUpgradeOptions {
  isOwner: boolean;
  isAuthenticated: boolean;
  userType: string | undefined;
  hasWorkspace: boolean;
  activeWorkspaceMemberCount: number | undefined;
}

function trackNavigateToUpgrade({
  isOwner,
  isAuthenticated,
  userType,
  hasWorkspace,
  activeWorkspaceMemberCount,
  eventPayload,
}: TrackNavigateToUpgradeOptions & {
  eventPayload: TrackNavigateToUpgradeEventPayload;
}) {
  const seriesRole = isAuthenticated
    ? isOwner
      ? 'Owner'
      : 'Collaborator'
    : null;

  const { event, properties } = eventPayload;
  trackUser({
    event,
    properties: {
      ...properties,
      role: seriesRole,
      'presentation owner': isOwner,
      'shared presentation': hasWorkspace,
      'active team members': activeWorkspaceMemberCount || 0,
      'current plan': userType ?? 'registered',
    },
  });
}

export function trackNavigateToPlansFromPaidFeaturesAlert(
  options: TrackNavigateToUpgradeOptions,
) {
  trackNavigateToUpgrade({
    ...options,
    eventPayload: {
      event: 'Plans from editor',
      properties: { context: 'Paid features upgrade' },
    },
  });
}

export const trackPressPresent = ({
  context = TrackingContext.Editview,
  placement = 'Present button header',
  role,
  workspaceRole,
  isWorkspacePresentation,
  seriesId,
  questionId,
}: {
  context?: TrackingContext;
  placement?: PresentButtonPlacement;
  role: UserPresentationRole;
  workspaceRole: WorkspaceRole | undefined;
  isWorkspacePresentation: boolean;
  seriesId: string;
  questionId: string;
}) => {
  GTMTracking.trackPlayPresentation(seriesId);
  trackUser({
    event: 'Pressed present',
    properties: {
      context,
      placement,
      role,
      'workspace role': workspaceRole,
      'shared presentation': isWorkspacePresentation,
      'series id': seriesId,
      'question id': questionId,
    },
  });
};

export const trackPresentWithResults = ({
  context,
  placement,
  role,
  participants,
}: {
  context: TrackingContext;
  placement: PresentButtonPlacement;
  role: UserPresentationRole;
  participants: number;
}) => {
  trackUser({
    event: 'Pressed present with results',
    properties: {
      context,
      placement,
      role,
      participants,
    },
  });
};

export const trackPresentedSkippedSlide = (
  placement: PresentButtonPlacement,
) => {
  trackUser({
    event: 'Presented skipped slide',
    properties: {
      placement,
    },
  });
};

export const trackOpenedRemoteView = () => {
  trackUser({
    event: 'Opened remote view',
    properties: {
      placement: 'Present chevron',
      context: TrackingContext.Editview,
    },
  });
};

export const trackCloseAddSlidePopover = (trigger: CancelAddSlideTrigger) => {
  trackUser({
    event: 'Clicked cancel add slide',
    properties: {
      context: TrackingContext.Editview,
      placement: 'Cancel add slide button',
      trigger,
    },
  });
};

export const trackOpenAddSlidePopover = (placement: AddSlidePlacement) => {
  trackUser({
    event: 'Clicked add slide',
    properties: {
      placement,
      context: TrackingContext.Editview,
    },
  });
};

export function trackCreatedLeaderboard({
  context = '',
}: { context?: string } = {}) {
  trackUser({
    event: 'Created leaderboard',
    properties: { context },
  });
}

export const trackViewedStartState = (trigger: StartStateTrigger) => {
  trackUser({
    event: 'Viewed start state',
    properties: {
      context: TrackingContext.Editview,
      trigger,
    },
  });
};

export const trackClickedStartStateQuickAction = (
  action: StartStateQuickAction,
) => {
  trackUser({
    event: 'Clicked start state quick action',
    properties: {
      context: TrackingContext.Editview,
      action,
    },
  });
};

export const trackUploadedImage = ({
  context,
  source,
  fileType,
  placement,
}: {
  context: string;
  source: string;
  fileType?: string | undefined;
  placement: string;
}) => {
  trackUser({
    event: 'Uploaded image',
    properties: {
      context,
      source,
      fileType,
      placement,
    },
  });
};

export const EDITOR_BOUNDARY_TRACKING_UTILS = {
  trackAddImageClicked,
  trackAddedImageOnOption,
  trackAddedOption,
  trackRemovedOption,
  trackModifiedOptionText,
  trackUploadedImage,
  trackToggledCorrectArea,
  trackUpdatedSegmentation,
  trackSwitchedSegmentation,
  trackOpenedSegmentation,
};

import { useState } from 'react';
import {
  Markdown,
  Text,
  Button,
  PopoverRoot,
  PopoverAnchor,
  PopoverContainer,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
} from '@mentimeter/ragnar-ui';
import type { GroupActionT } from '@mentimeter/ragnar-ui';

interface StatusErrorT extends GroupActionT {
  show: boolean;
  title?: string | undefined;
  message: string;
  description?: string | undefined;
  onDismissPopover: () => void;
  children: React.ReactNode;
}

export const StatusErrorPopover = ({
  show,
  title = 'Something went wrong...',
  message,
  description = '',
  actions,
  onDismissPopover,
  children,
}: StatusErrorT) => {
  const [showDescription, setShowDescription] = useState(false);

  return (
    <PopoverRoot
      open={show}
      onOpenChange={(open) => (!open ? onDismissPopover() : undefined)}
    >
      <PopoverAnchor>{children}</PopoverAnchor>
      <PopoverContainer width="320px">
        <PopoverHeader>{title}</PopoverHeader>
        <PopoverBody>
          <Text>{message}</Text>
          {!showDescription && (
            <Button
              mt={1}
              variant="subtle"
              onClick={() => setShowDescription(!showDescription)}
            >
              Read more
            </Button>
          )}
          {showDescription && (
            <Text mt={1}>
              <Markdown>{description}</Markdown>
            </Text>
          )}
        </PopoverBody>
        {actions && (
          <PopoverFooter>
            {actions.map((action) => (
              <Button
                variant="primary"
                size="compact"
                key={action.key}
                onClick={action.onClick}
              >
                {action.children}
              </Button>
            ))}
          </PopoverFooter>
        )}
      </PopoverContainer>
    </PopoverRoot>
  );
};

const EDITOR = 'editor';
const SERIES = 'series';
const QUESTION = 'question';
const RESULTS = 'results';
const QUIZ_PLAYERS_SCORE = 'quiz-players-score';
const RATING_GRIDS = 'rating-grids';

export const seriesCacheKey = (seriesId: string) => [EDITOR, SERIES, seriesId];
export const questionsCacheKey = (seriesId: string) => [
  EDITOR,
  SERIES,
  seriesId,
  QUESTION,
];

export const resultCacheKey = (questionId: string) => [
  EDITOR,
  QUESTION,
  questionId,
  RESULTS,
];

export const quizPlayersScoreCacheKey = (questionId: string) => [
  EDITOR,
  QUESTION,
  questionId,
  QUIZ_PLAYERS_SCORE,
];

export const ratingGridsCacheKey = () => [EDITOR, RATING_GRIDS];
export const ratingGridCacheKey = (id: string) => [EDITOR, RATING_GRIDS, id];

export const cacheKeys = {
  seriesCacheKey,
  questionsCacheKey,
  resultCacheKey,
  quizPlayersScoreCacheKey,
  ratingGridsCacheKey,
  ratingGridCacheKey,
};

import { usePathname } from '@mentimeter/next-navigation';
import { SessionStorage } from '@mentimeter/storage';
import { useEffect, useState } from 'react';

type Context = 'default' | 'back-from-editor';

const clearReturnToValues = (context: Context) => {
  SessionStorage.removeItem(context + '-return-href');
  SessionStorage.removeItem(context + '-return-label');
};

export const useReturnTo = (context: Context = 'default') => {
  const [returnHref] = useState(
    SessionStorage.getItem(context + '-return-href'),
  );
  const [returnLabel] = useState(
    SessionStorage.getItem(context + '-return-label'),
  );

  return {
    clearReturnToValues: () => clearReturnToValues(context),
    returnHref,
    returnLabel,
  };
};

export const usePageAnchor = (
  currentPageLabel: string,
  context: Context = 'default',
) => {
  const pathname = usePathname();

  useEffect(() => {
    SessionStorage.setItem({
      key: context + '-return-href',
      value: pathname,
      type: 'functional',
    });

    SessionStorage.setItem({
      key: context + '-return-label',
      value: currentPageLabel,
      type: 'functional',
    });

    return () => {
      clearReturnToValues(context);
    };
  }, [context, currentPageLabel, pathname]);
};

import { Dark, P } from '@mentimeter/ragnar-ui';

export const PresentationOccupiedUI = ({
  presenterName,
}: {
  presenterName: string;
}) => {
  return (
    <Dark>
      <P>
        Starting this presentation now might interrupt {presenterName} and
        affect voting results.
      </P>
      <P>
        If you&apos;d also like to present now, you can duplicate this
        presentation.
      </P>
    </Dark>
  );
};

// This file is automatically generated. DO NOT EDIT.

import { fromSnakeKeys } from '@api/internal';

export interface GetGetByWorkspaceResponse {
  /** The message to send to the user when they request to upgrade their role */
  message: string;
}

export async function getGetByWorkspaceResponse(
  response: Response,
): Promise<GetGetByWorkspaceResponse> {
  const json = await response.json();
  return fromSnakeKeys(json) as GetGetByWorkspaceResponse;
}

import { cva, type VariantProps } from '@mentimeter/ragnar-tailwind-config';

export const menuItemVariants = cva(
  [
    'flex',
    'flex-row',
    'items-center',
    'bg-surface',
    'p-2',
    'transition-all',
    'duration-100',
    'text-87.5',
    'leading-normal',
    'font-body',
    'cursor-pointer',
    'rounded-lg',
    'gap-3',
    'aria-disabled:cursor-not-allowed',
    'aria-disabled:text-on-disabled',
    'aria-disabled:bg-disabled',
  ],
  {
    variants: {
      variant: {
        default: [
          'text',
          'focus:bg-secondary-weakest',
          'focus:text-on-secondary-weakest',
          'data-[state="checked"]:text-on-secondary-weakest',
          'data-[state="checked"]:bg-secondary-weakest',
          'data-[state="checked"]:shadow-[0_0_0_1px_inset]',
          'data-[state="checked"]:shadow-secondary',
          'data-[state="checked"]:focus:bg-secondary-weak',
          'data-[state="checked"]:focus:text-on-secondary-weak',
        ],
        negative: [
          'text-negative',
          'focus:bg-negative-weakest',
          'focus:text-on-negative-weakest',
          'data-[state="checked"]:bg-negative-weakest',
          'data-[state="checked"]:text-on-negative-weakest',
          'data-[state="checked"]:shadow-[0_0_0_1px_inset]',
          'data-[state="checked"]:shadow-negative',
          'data-[state="checked"]:focus:bg-negative-weak',
          'data-[state="checked"]:focus:text-on-negative-weak',
        ],
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export type MenuItemVariantProps = VariantProps<typeof menuItemVariants>;

import React, { Suspense } from 'react';
import type { IconProps } from '@mentimeter/question-modules-shared';
import type { ModuleId } from '@mentimeter/http-clients';
import { Box } from '@mentimeter/ragnar-ui';
import { importIconDynamically } from './importIconDynamically';

interface Props extends IconProps {
  moduleId: ModuleId;
}
export const Icon = React.memo<Props>(({ moduleId, ...props }: Props) => {
  if (!moduleId) return null;

  const Icon = importIconDynamically(moduleId);
  return (
    <Suspense fallback={<Box width="32px" height="32px" bg="transparent" />}>
      <Icon {...props} />
    </Suspense>
  );
});

import { TrackingContext } from '@mentimeter/http-clients';
import { trackUser } from '@api/tracking/client';

export const trackBackButton = (trackingContext: TrackingContext) => {
  trackUser({
    event: 'Back to my presentations',
    properties: {
      placement: 'Back button',
      context: trackingContext,
    },
  });
};
export const trackClickAvatar = (
  role: string,
  trackingContext: TrackingContext = TrackingContext.Editview,
) => {
  trackUser({
    event: 'Clicked on avatar',
    properties: {
      placement: 'Header',
      context: trackingContext,
      role,
    },
  });
};

export const trackEnablePreviewMode = (placement: string) => {
  trackUser({
    event: 'Opened preview mode',
    properties: {
      placement,
      context: TrackingContext.Editview,
    },
  });
};

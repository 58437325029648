import * as React from 'react';
import { ThemeProvider, useRagnar } from '@mentimeter/ragnar-react';
import { EDITOR_DSC } from '@mentimeter/editor-essentials/constants';

export const EditorThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { theme } = useRagnar();
  return (
    <ThemeProvider theme={{ ...EDITOR_DSC, colors: { ...theme.colors } }}>
      {children}
    </ThemeProvider>
  );
};

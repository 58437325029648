import { getSeriesRequest } from '@core-api/editor/editor/series/{series_id}';
import { createEditorSeriesCompatibilityLayer } from '@mentimeter/compatibility/editor';
import { seriesCacheKey, useData } from '@mentimeter/core-hooks';
import type { SeriesWithSlideDeck } from '@mentimeter/editor-schema/api-types-overrides';
import { useCompatibilitySeriesFetcher } from '@mentimeter/migration-utils';
import { useCallback, useMemo } from 'react';
import { type SWRConfiguration } from 'swr';
import invariant from 'tiny-invariant';

interface UseEditorSeriesOptions {
  swrConfig?: SWRConfiguration;
  getSeriesCacheKey?: (seriesId: string) => string | Array<string>;
}

interface UseEditorSeries {
  series: SeriesWithSlideDeck | undefined;
  error: any;
  revalidate: () => Promise<{
    responseForBackwardCompat: SeriesWithSlideDeck | undefined;
  }>;
  lazyGetSeries: () => SeriesWithSlideDeck | undefined;
}

/**
 * Fetches a "series with a slide deck" with backwards compatibility to our legacy domain model.
 * @remarks Requires an **authenticated client** and it should **only** be used within the editor app.
 */
export const useEditorSeries = (
  seriesId: string,
  options: UseEditorSeriesOptions = {},
): UseEditorSeries => {
  invariant(seriesId, 'useEditorSeries(SWR): seriesId is required');
  const getCacheKey = options?.getSeriesCacheKey ?? seriesCacheKey;
  const cacheKey = getCacheKey(seriesId);
  const shouldUseNewEndpoints = true;

  const fetcher = useCompatibilitySeriesFetcher<SeriesWithSlideDeck>(
    seriesId,
    getSeriesRequest,
    shouldUseNewEndpoints,
  );

  const swrConfig: SWRConfiguration = {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    focusThrottleInterval: 1000 * 60 * 2,
    ...options.swrConfig,
  };

  const {
    data: series,
    error,
    revalidate,
    lazyData,
  } = useData<SeriesWithSlideDeck>(
    {
      cacheKey,
      seriesId,
      fetcher,
    },
    swrConfig,
  );

  const wrappedLazyData = useCallback(() => {
    const series = lazyData();
    return createEditorSeriesCompatibilityLayer(series);
  }, [lazyData]);

  return useMemo(() => {
    return {
      series: createEditorSeriesCompatibilityLayer(series),
      error,
      revalidate,
      lazyGetSeries: wrappedLazyData,
    };
  }, [series, error, revalidate, wrappedLazyData]);
};

import { useWorkspaceRequestUpgradeMessage } from '@mentimeter/workspace-data-hooks';

export function RoleUpgradeDisabledDataHandler({
  children,
  workspaceId,
}: {
  children: ({ message }: { message: string }) => React.ReactNode;
  workspaceId: number | undefined;
}) {
  const { message } = useWorkspaceRequestUpgradeMessage(workspaceId);

  if (!message) {
    return null;
  }

  return children({ message });
}

'use client';
import type { SlideDeck as EditorSlideDeck } from '@core-api/editor/types/response';
import { MigrationStatusContext } from '@mentimeter/compatibility/migration-status';
// eslint-disable-next-line menti-react/forbidden-legacy-series-import
import type { Series } from '@mentimeter/http-clients/edge';
// eslint-disable-next-line menti-react/forbidden-app-import
import type { SlideDeck as PresentationSlideDeck } from '@mentimeter/presentation-schema/api-types-overrides';
import { useMemo, type PropsWithChildren } from 'react';

interface MigrationContainerProps<
  SlideDeckT extends EditorSlideDeck | PresentationSlideDeck,
> {
  seriesResponse?: {
    series: Series | undefined;
    slideDeck: SlideDeckT | undefined;
  };
  isMigrated?: boolean;
}

export function MigrationContainer<
  SlideDeckT extends EditorSlideDeck | PresentationSlideDeck,
>({
  seriesResponse,
  isMigrated,
  children,
}: PropsWithChildren<MigrationContainerProps<SlideDeckT>>) {
  const { slideDeck } = seriesResponse ?? {};
  const migrationStatusContextValue = useMemo(
    () => ({
      isSlideDeckMigrated: isMigrated ?? Boolean(slideDeck),
    }),
    [isMigrated, slideDeck],
  );

  return (
    <MigrationStatusContext.Provider value={migrationStatusContextValue}>
      {children}
    </MigrationStatusContext.Provider>
  );
}

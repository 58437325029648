import { useDeviceChecker } from '@mentimeter/ragnar-device';
import type { KosmosSpacing } from '@mentimeter/ragnar-dsc';
import {
  Box,
  Button,
  ButtonGroup,
  Dark,
  DropdownMenuItem,
  DropdownMenuItemIcon,
  PopoverBody,
  PopoverContainer,
  PopoverFooter,
  PopoverHeader,
  Prompt,
  Text,
  TooltipGeneric,
  type ButtonT,
} from '@mentimeter/ragnar-ui';
import { ChevronDownIcon, PlaySolidIcon } from '@mentimeter/ragnar-visuals';
import React, { forwardRef, type ComponentProps } from 'react';
import {
  MISSING_LEADERBOARD_ACTION_TEXT,
  MISSING_LEADERBOARD_BODY,
  MISSING_LEADERBOARD_TITLE,
} from '../data-handlers/HeaderPresentDataHandler/constants';
import { PresentationOccupiedUI } from './PresentationOccupiedUI';

const HeaderButton = ({ children, ...props }: ButtonT) => {
  const { isMobile } = useDeviceChecker();
  const paddings: Record<string, { pl: KosmosSpacing; pr: KosmosSpacing }> = {
    compact: { pl: 'space4', pr: 'space3' },
    default: { pl: 'space5', pr: 'space3' },
  };

  const padding =
    paddings[props.size as keyof typeof props.size] || paddings.default;

  return (
    <Button
      pl={padding?.pl}
      pr={padding?.pr}
      variant="primary"
      size="compact"
      {...props}
    >
      {isMobile ? (
        <Box ml="2px">
          <PlaySolidIcon size={2} color="currentColor" />
        </Box>
      ) : (
        children
      )}
    </Button>
  );
};

const DropdownTrigger = React.forwardRef(
  (
    props: {
      id: string;
    } & ButtonT,
    ref,
  ) => {
    const paddings: Record<string, { pl: KosmosSpacing; pr: KosmosSpacing }> = {
      compact: { pl: 'space1', pr: 'space2' },
      default: { pl: 'space2', pr: 'space3' },
    };

    const padding =
      paddings[props.size as keyof typeof props.size] || paddings.default;

    return (
      <Button
        ref={ref}
        variant="primary"
        pl={padding?.pl}
        pr={padding?.pr}
        {...props}
      >
        <ChevronDownIcon size={2} color="currentColor" />
      </Button>
    );
  },
);

const DisabledPresentPopover = ({
  presenterName,
}: {
  presenterName: string;
}) => {
  return (
    <PopoverContainer width="300px" className="dark">
      <Dark>
        <PopoverHeader>{`${presenterName} is currently presenting`}</PopoverHeader>
      </Dark>
      <PopoverBody>
        <PresentationOccupiedUI presenterName={presenterName} />
      </PopoverBody>
    </PopoverContainer>
  );
};

/**
 * Informing the user that someone is already presenting in a tooltip format
 */
const CtaTooltip = ({
  presenterName,
  show,
  referenceId,
  children,
}: React.PropsWithChildren<{
  show: boolean;
  referenceId: string;
  presenterName: string;
}>) => {
  if (!show) return null;

  return (
    <TooltipGeneric
      placement="bottom"
      showArrow={false}
      referenceId={referenceId}
    >
      <Prompt
        theme="dark"
        p={3}
        width="300px"
        title={`${presenterName} is currently presenting`}
      >
        {children}
      </Prompt>
    </TooltipGeneric>
  );
};

const QuizWarningPopover = ({
  onPresent,
  onCancel,
}: {
  onPresent: () => void;
  onCancel: () => void;
}) => {
  return (
    <PopoverContainer width="350px" className="dark">
      <Dark>
        <PopoverHeader>{MISSING_LEADERBOARD_TITLE}</PopoverHeader>
      </Dark>
      <PopoverBody>
        <Text>{MISSING_LEADERBOARD_BODY}</Text>
      </PopoverBody>
      <PopoverFooter>
        <Button variant="secondary" size="compact" onClick={onPresent}>
          Present anyway
        </Button>
        <Button variant="primary" size="compact" onClick={onCancel}>
          {MISSING_LEADERBOARD_ACTION_TEXT}
        </Button>
      </PopoverFooter>
    </PopoverContainer>
  );
};

const HeaderCta = forwardRef(
  (props: ComponentProps<typeof ButtonGroup>, forwardedRef) => (
    <ButtonGroup ref={forwardedRef} {...props} showSeparators />
  ),
);

interface CtaDropdownItem
  extends Omit<ComponentProps<typeof DropdownMenuItem>, 'onSelect'> {
  information?: string | undefined;
  icon?: React.ElementType | undefined;
  onSelect: () => void;
}

export const CtaDropdownItem = ({
  children,
  information,
  disabled,
  onSelect,
  icon: Icon,
  id,
}: CtaDropdownItem) => (
  <DropdownMenuItem
    onSelect={onSelect}
    disabled={Boolean(disabled)}
    flexDirection="row"
    alignItems="flex-start"
    id={id}
  >
    <DropdownMenuItemIcon>
      {Icon && (
        <Box mt="space1">
          <Icon />
        </Box>
      )}
    </DropdownMenuItemIcon>
    <Box flex="1 1 auto">
      <Text>{children}</Text>
      <Text fontSize="87.5" color="textWeaker">
        {information}
      </Text>
    </Box>
  </DropdownMenuItem>
);

const HeaderCtaNamespace = Object.assign(HeaderCta, {
  DropdownTrigger,
  Button: HeaderButton,
  DisabledPresentPopover,
  Tooltip: CtaTooltip,
  QuizWarningPopover,
  PresentationOccupiedUI,
  CtaDropdownItem,
});

export { HeaderCtaNamespace as HeaderCta };

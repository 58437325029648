import React, { forwardRef } from 'react';
import { Tooltip, type IconButtonProps } from '@mentimeter/ragnar-ui';
import { PlusIcon } from '@mentimeter/ragnar-visuals';
import { VIEW_ONLY_DISABLED_MESSAGE } from '@mentimeter/editor-essentials/constants';
import { HeaderIconButton } from './UI/HeaderIconButton';

interface HeaderInviteTeamMemberButtonProps extends IconButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

export const HeaderInviteTeamMemberButton = forwardRef<
  HTMLButtonElement,
  HeaderInviteTeamMemberButtonProps
>(({ onClick, disabled, ...rest }, ref) => {
  return (
    <HeaderIconButton
      {...rest}
      id="header-invite-team-member-button"
      onClick={onClick}
      ref={ref}
      disabled={disabled}
    >
      <Tooltip
        referenceId="header-invite-team-member-button"
        description={disabled ? VIEW_ONLY_DISABLED_MESSAGE : 'Add more people'}
        placement="bottom"
      />
      <PlusIcon color="inherit" />
    </HeaderIconButton>
  );
});

HeaderInviteTeamMemberButton.displayName = 'HeaderInviteTeamMemberButton';

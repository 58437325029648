import { useMatch } from './use-match';

export const useDeviceChecker = () => {
  const isMobile = useMatch({ lessThan: 2 });
  const isSmallDevice = useMatch({ lessThan: 3 });

  return {
    isMobile,
    isSmallDevice,
  };
};
